import { axiosInstance, endpoints } from "./axiosHandler";

function agfdAPIUrlForID(id) {
  return endpoints.autograb + id + "/";
}

function refreshAGFD(afgd) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(afgd.url)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchAGFDs(filters, cancelToken) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.autograb, {
        params: filters,
        cancelToken: cancelToken.token
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateAGFDAdjustment(agfd, percentage) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(endpoints.autograb + agfd.id + "/", {
        adjusted_trade_price_percentage: percentage
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAGFDRego(searchDict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.autograb + "request-price-rego/", searchDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAGFDVIN(searchDict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.autograb + "request-price-vin/", searchDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestVehicleReportVIN(vin) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.autograb + "request-report-no-pricing/", {
        params: {
          vin: vin
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAGFDID(id, mileage, title) {
  let searchDict = {
    id: id,
    mileage: mileage,
    title: title
  };

  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.autograb + "request-price-id/", searchDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAGFacet(facetName, extras) {
  let searchDict = {
    facet_name: facetName,
    extras: extras
  };

  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.autograb + "request-facet/", { params: searchDict })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function searchAGWithFacets(extras) {
  let searchDict = {
    extras: extras
  };

  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.autograb + "search-with-facet/", { params: searchDict })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabLiveMarketFromAGFD(agfd_id, age, include_adjacent_years) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(agfdAPIUrlForID(agfd_id) + "live-market/", {
        params: {
          age: age,
          include_adjacent_years: include_adjacent_years
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabVehicleDetailsFromAGFD(form_id) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(agfdAPIUrlForID(form_id) + "request-vehicle-info/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestAutoGrabVehicleReport(agfd_id) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(agfdAPIUrlForID(agfd_id) + "request-report/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export {
  fetchAGFDs,
  refreshAGFD,
  requestAutoGrabLiveMarketFromAGFD,
  requestAutoGrabVehicleDetailsFromAGFD,
  updateAGFDAdjustment,
  requestAGFDRego,
  requestAGFDVIN,
  requestVehicleReportVIN,
  requestAGFDID,
  requestAGFacet,
  searchAGWithFacets,
  requestAutoGrabVehicleReport
};
