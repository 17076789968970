<template>
  <div class="dropdown shadow padding-1-05 min-width-320 flex-column">
    <div class="flex-row">
      <h4 class="h4 margin-right-auto vertical-flex-center">{{ title }}</h4>
      <button class="no-style margin-left" v-on:click="emitClose()"><img src="/assets/img/icon-close.svg" /></button>
    </div>
    <form @submit.prevent="emitSubmit()" class="flex-column">
      <div class="flex-row margin-top">
        <input required v-model="text" :type="type" class="text-field flex-grow" placeholder="Value" />
        <p v-if="units" class="body-2 vertical-flex-center margin-left-05 body-2">{{ units }}</p>
      </div>
      <div class="flex-row">
        <primary-button title="Apply" class="margin-top margin-left-auto min-width-100" type="submit" />
      </div>
    </form>
  </div>
</template>

<script>
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
export default {
  name: "InputDropBox",
  props: {
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    units: {
      type: String,
      default: ""
    }
  },
  components: {
    PrimaryButton
  },
  data: function () {
    return {
      text: ""
    };
  },
  methods: {
    emitClose: function () {
      this.$emit("onClose");
    },
    emitSubmit: function () {
      this.$emit("onSubmit", this.text);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.no-style {
  border: none;
  background: none;
}
</style>
